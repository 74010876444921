import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Card,
  CardContent,
  Typography,
  Modal,
  IconButton,
  Grid,
} from '@material-ui/core';
import { AlertStyle } from './AlertStyle';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from '../../contexts/Localization';

const useStyles = AlertStyle();

function Alert({
  openModal,
  alertTitle,
  alertBody,
  onClose,
  okCallBack,
  alertText,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    if (okCallBack) {
      okCallBack();
    }
    onClose();
  };

  return (
    <Modal
      className={classes.backdrop}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(233, 233, 235, 0.78)',
        },
      }}
      open={openModal}
      aria-describedby="alert notification"
      onClose={handleClose}
    >
      <div style={{ outline: 'none' }}>
        <Grid container style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            aria-label={t('Close')}
            className={classes.closeOverlay}
            onClick={handleClose}
          >
            <CloseIcon
              style={{ width: '25px', height: '25px' }}
              titleAccess="Close Button"
              tabIndex="0"
            />
          </IconButton>
        </Grid>
        <Grid container>
          <Card className={classes.root}>
            <CardContent className={classes.card_content}>
              <Typography className={classes.textOneStyle} id="alert">
                {alertTitle}
              </Typography>
              <Typography className={classes.textTwoStyle} id="notification">
                {alertBody}
              </Typography>
              <Button
                variant="contained"
                autoFocus
                className={classes.saveAndExit}
                onClick={handleClose}
                style={{ padding: 0 }}
              >
                {alertText ? alertText : t('Ok')}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </div>
    </Modal>
  );
}

export default Alert;
