import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import styles from "./Enrollment.module.scss";
import { useTranslation } from "../contexts/Localization";
import { useGlobalUserData } from "../lib/globalUserData";

const ChangePasswordSuccess = () => {
  const { changeStep } = useGlobalUserData();
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      changeStep("fislogin");
    }, 3000);
  }, []);
  return (
    <Grid container justifyContent="center">
      <Grid item md={4}>
        <Box className={styles.container}>
          <Box
            className={styles.heading}
            style={{ color: "#e37617", fontSize: "24px", textAlign: "center" }}
          >
            {t("Your password was successfully updated")}!
          </Box>
          <Box style={{ margin: "70px", fontSize: "8rem" }}>
            <CheckCircleIcon fontSize="inherit" style={{ fill: "green" }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChangePasswordSuccess;
