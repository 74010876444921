import { Button, Box, Link } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { useTranslation } from "../contexts/Localization";
import styles from "./Enrollment.module.scss";
import { useGlobalUserData } from "../lib/globalUserData";
import { axiosInstance } from "../services/customaxios";
import LoaderFis from "../components/Loader/LoaderFIS";
import FisModal from "./Modal";
import CustomOTP from "./CustomOTP";
import FisAlert from "./FisAlert";
import Alert from "../components/Alert/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FISWrapper } from "./FISWrapper";
import { getMessage } from "../utils/status";

const Otp = () => {
  const { userData, changeStep, showGlobalAlert, globalAlert, updateUserData } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ title: "", body: "" });
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [otpValues, setOtpValues] = useState({
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    field6: "",
  });

  useEffect(() => {
    showGlobalAlert({ type: "", message: "" });
    return () => {
      if (
        globalAlert.message === "Your security code was sent again" ||
        globalAlert.message === "Please provide a valid One time passcode"
      ) {
        showGlobalAlert({ type: "", message: "" });
      }
    };
  }, []);

  useEffect(() => {
    const inputField = document.querySelector(`input[name=field1]`);
    inputField?.focus();
  }, [showAlert]);

  useEffect(() => {
    const requiredFields = [
      "field1",
      "field2",
      "field3",
      "field4",
      "field5",
      "field6",
    ];
    for (let field of requiredFields) {
      if (!otpValues[field]) {
        break;
      }
    }
  }, [otpValues, error]);

  const alertOpen = () => {
    setShowAlert(true);
  };
  const alertClose = () => {
    setShowAlert(false);
  };

  useEffect(async () => {
    if (checkValid()) {
      showGlobalAlert({ type: "", message: "" });
    }
  }, [otpValues]);

  const handleKeyDown = (e) => {
    const fieldname = e.target.name.substr(e.target.name.length - 1);
    const fieldIntIndex = parseInt(fieldname, 10);
    const { maxLength, value } = e.target;
    if (
      value.length < maxLength &&
      fieldIntIndex <= 6 &&
      e.code === "Backspace"
    ) {
      const prevfield = document.querySelector(
        `input[name=field${fieldIntIndex - 1}]`
      );
      if (prevfield !== null) {
        prevfield.focus();
      }
    }
  };

  const handleOTPChange = (event) => {
    if (event.target.value.match(/^\d*$/)) {
      const { value } = event.target;
      const fieldname = event.target.name.substr(event.target.name.length - 1);
      setOtpValues({ ...otpValues, [event.target.name]: event.target.value });
      const fieldIndex = parseInt(fieldname, 10);
      if (value.length >= event.target.maxLength && fieldIndex < 6) {
        const jumpToNextField = document.querySelector(
          `input[name=field${fieldIndex + 1}]`
        );
        if (jumpToNextField !== null) {
          jumpToNextField.focus();
        }
      }
    }
  };
  const getAlertmessage = (troubleStep) => {
    const messages = {
      troublesigninforgotpass: {
        title: "Temporary Password Sent",
        body: "A temporary password has been sent to your email. Please check your email and try to login.",
      },
      troublesigninforgotuser: {
        title: "Email Sent",
        body: "Your user ID has been sent to your registered email.",
      },
      troublesigninaccountlocked: {
        title: "Email Sent",
        body: "An email with a link to unlock your account has been sent to your registered email.",
      },
    };
    return messages[troubleStep];
  };

  const submitOtpAfterValidation = () => {
    showGlobalAlert({
      type: "",
      message: "",
    });
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/forgotusername`,
        {
          step: "username",
          context: "trouble sign in recover user id",
          data: {
            next: "true",
            emailOrLoginID: userData.userid,
            email: "true",
            selectedDeviceId: userData.otpchoice,
            otpPin: Object.values(otpValues).join(""),
            issueType: userData.issueTypes[userData.troubleSigninStep],
          },
        },
        {
          // withCredentials: 'include',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: "idm",
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status.code === 316) {
          if (res.data.result[0].nextStep.id === "otp") {
            alertOpen();
            setAlertMessage({
              title: t("Forgot Password"),
              body: t(
                "Your One Time Passcode is invalid. Please check and try again. Your login credentials will be locked after 3 wrong attempts consecutively."
              ),
            });
          } else {
            showGlobalAlert({
              type: "success",
              message:
                "Your One Time Passcode is invalid. Please check and try again. Your login credentials will be locked after 3 wrong attempts consecutively.",
            });
            changeStep("fislogin");
          }
        } else if (res.data.status.code === 200) {
          const { body } = getAlertmessage(userData.troubleSigninStep);
          showGlobalAlert({ type: "success", message: body });
          changeStep("fislogin");
        } else if (res.data.status.code >= 202 && res.data.status.code <= 209) {
          showGlobalAlert({
            type: "error",
            message: getMessage(res.data.status.code).body,
          });
          changeStep("fislogin");
        } else {
          alertOpen();
          setAlertMessage({
            title: t("Error occurred"),
            body: t(
              "We are unable to process your request. Please try again later."
            ),
          });
        }
      })
      .catch((err) => {
        const inputField = document.querySelector(`input[name=field1]`);
        inputField?.focus();
        setOtpValues({
          field1: "",
          field2: "",
          field3: "",
          field4: "",
          field5: "",
          field6: "",
        });
        setLoading(false);
        console.error(err);
        updateUserData({
          ...userData,
          userpassword: "",
          password: "",
        });
        alertOpen();
        setAlertMessage({
          title: t("Error occurred"),
          body: t(
            "We are unable to process your request. Please try again later."
          ),
        });
      });
  };
  const checkValid = () => !Object.values(otpValues).some((otp) => otp === "");

  const submitOtp = () => {
    if (checkValid()) {
      submitOtpAfterValidation();
    } else {
      showGlobalAlert({
        type: "error",
        message: "Please provide a valid One time passcode",
      });
    }
  };
  const resendOTP = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/sendOtp`,
        {
          loginName: userData.userid,
          selectedOtpDevices: [
            {
              id: userData.otpchoice,
            },
          ],
        },
        {
          withCredentials: "include",
          headers: {
            appid: "idm",
            language: apiLanguage,
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        const inputField = document.querySelector(`input[name=field1]`);
        inputField?.focus();
        setOtpValues({
          field1: "",
          field2: "",
          field3: "",
          field4: "",
          field5: "",
          field6: "",
        });
        if (res.data.status.code === 204) {
          showGlobalAlert({
            type: "error",
            message: getMessage("defaultCode").body,
          });
          changeStep("fislogin");
        } else if (res.data.status.code !== 200) {
          showGlobalAlert({
            type: "error",
            message: getMessage(res.data.status.code).body,
          });
          changeStep("fislogin");
        } else {
          showGlobalAlert({
            type: "success",
            message: "Your security code was sent again",
          });
        }
      })
      .catch((err) => {
        updateUserData({
          ...userData,
          userpassword: "",
          password: "",
        });
        const inputField = document.querySelector(`input[name=field1]`);
        inputField?.focus();
        setOtpValues({
          field1: "",
          field2: "",
          field3: "",
          field4: "",
          field5: "",
          field6: "",
        });
        setLoading(false);
        console.error(err);
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
      });
  };

  const navigateBack = () => {
    showGlobalAlert({ type: "", message: "" });
    changeStep("fistroubleotpchoice");
  };

  return (
    <>
      <FISWrapper>
        <Box
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            marginLeft: "-3rem",
          }}
        >
          <div>
            <ArrowBackIcon
              data-testid="backButtonOTP"
              name="backButtonOTP"
              onClick={navigateBack}
            />
          </div>
          <Box
            className={styles.heading_verification}
            id="mainHeading"
            name="mainHeading"
          >
            {t("Additional Verification Required")}
          </Box>
        </Box>
        <Box
          className={styles.subHeading}
          id="6-digit-pin-Text"
          name="6-digit-pin-text"
        >
          {t("Please enter the 6-digit PIN below")}
        </Box>
        <form
          style={{ width: "70%", marginTop: "20px" }}
          noValidate
          id="otpPage"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {globalAlert.message && (
            <FisAlert message={globalAlert.message} type={globalAlert.type} />
          )}
          <CustomOTP
            otpValues={otpValues}
            handleChange={handleOTPChange}
            data={{}}
            txnId={{}}
            setOtpValues={setOtpValues}
            setError={setError}
            handleResend={resendOTP}
            handleKeyDown={handleKeyDown}
          />
          <Box className={styles.btn_wrapper}>
            <Button
              id="continue-btn"
              name="continueBtn"
              variant="text"
              onClick={submitOtp}
              className={styles.login_button}
              type="submit"
            >
              {t("Continue")}
            </Button>
          </Box>
        </form>
        <Box className={styles.link_wrapper} id="linkWrapper">
          <Link
            component="button"
            color="primary"
            variant="body2"
            id="loginBtn"
            onClick={() => {
              changeStep("fislogin");
              // showGlobalAlert({ type: '', message: '' });
            }}
            style={{ textDecoration: "underline" }}
          >
            {t("Log in with a different User ID")}
          </Link>
        </Box>
      </FISWrapper>
      <FisModal
        open={showAlert}
        alertTitle={alertMessage.title}
        id={alertMessage.title}
        onConfirm={() => {
          setOtpValues({
            field1: "",
            field2: "",
            field3: "",
            field4: "",
            field5: "",
            field6: "",
          });
          alertClose();
        }}
        onClose={alertClose}
        alertBody={alertMessage.body}
      />
      <LoaderFis open={loading} />
    </>
  );
};

export default Otp;
