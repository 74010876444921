import React from 'react';
import { Backdrop, Grid, CircularProgress, Box, Link } from '@material-ui/core';

import { MigLoaderStyles } from './MigLoaderStyles';
import './LoaderCSS.css';
import styles from './MigLoader.module.scss';
import orientalLogoLargeLoader from '../../images/orientalLogoLargeLoader.svg';
import alertTriangle from '../../images/alertTriangle.svg';
import { useGlobalUserData } from '../../lib/globalUserData';


const useStyles = MigLoaderStyles();

const MigLoader = ({
  open,
  messages = {},
  migFailed = false,
  setOpen,
  setMigMsg,
  setMigFailed
}) => {
  const classes = useStyles();
  const { changeStep } = useGlobalUserData();
  return (
    <Backdrop
      className={classes.backdrop}
      role="presenter"
      open={open}
      aria-hidden="true"
      aria-disabled="true"
    >
      <Grid container className={classes.center_style}>
        <Grid
          item
          xs={12}
          // className={classes.center_style}
          // style={{ marginBottom: 40 }}
        >
          <Box className={styles.container}>
            <img
              src={orientalLogoLargeLoader}
              className={classes.loaderStyle}
              alt="Loading..."
            />
            {migFailed ? (
              <img
                src={alertTriangle}
                // className={classes.loaderStyle}
                style={{ margin: '20px' }}
                alt="Failed"
              />
            ) : (
              <CircularProgress
                style={{ color: '#E37617' }}
                size={45}
                thickness={4}
                data-testid="loading"
              />
            )}
            <Box
              style={{
                marginBottom: !migFailed ? '30px' : '',
                fontFamily: 'Inter',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              <Box style={{ marginBottom: '12px' }}>
                {messages.es ||
                  'Quédate conectado mientras actualizamos tu Banca Online.'}
              </Box>
              <Box>
                {messages.en ||
                  'Stay tuned while we update your online banking.'}
              </Box>
              {migFailed && (
                <Box
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '13px',
                    fontWeight: 'bold',
                    marginTop: '10px'
                  }}
                >
                  <Link
                    component="button"
                    variant="body2"
                    color="primary"
                    onClick={() => {
                      setOpen(false);
                      changeStep('fislogin');
                      setMigMsg({
                        es: 'Quédate conectado mientras actualizamos tu Banca Online.',
                        en: 'Stay tuned while we update your online banking.',
                      });
                      setMigFailed(false);
                    }}
                    style={{
                      textDecoration: 'underline',
                      fontFamily: 'Inter',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Please login again
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default MigLoader;
