import { makeStyles } from "@material-ui/core";

export const commonStyle = (theme) => {
  return {
    center_style: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },

    loading: {
      font: "normal normal bold 22px/24px Arial",
      letterSpacing: "0.21px",
      color: "#000000",
      opacity: 1,
      marginTop: "4px",
      paddingLeft: "32px",
      textAlign: "center",
      [theme.breakpoints.down("600")]: {
        font: " normal normal bold 22px/34px Arial",
        paddingLeft: "22px",
      },
    },

    smallLoading: {
      font: "normal normal bold 15px/20px Arial",
      letterSpacing: "0.21px",
      color: "#000000",
      opacity: 1,
      marginTop: "4px",
      paddingLeft: "32px",
      textAlign: "center",
      [theme.breakpoints.down("600")]: {
        font: " normal normal bold 22px/34px Arial",
        paddingLeft: "22px",
      },
    },
    loaderStyle: {
      width: "114px",
      [theme.breakpoints.down("600")]: {
        width: "90px",
      },
    },

    loader_circle: {
      width: "105px",
      [theme.breakpoints.down("600")]: {
        width: "90px",
      },
    },
  };
};

export const LoaderStyles = () => {
  
  return makeStyles((theme) => {
    const style = commonStyle(theme);
    return ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      // backgroundColor: 'rgba(233, 233, 235, 0.60)',
    },
    ...style,
  })});
};
