import { LanguageProvider } from "./contexts/Localization";
import { UserDataProvider } from "./lib/globalUserData";
import Wizard from "./Wizard/Wizard";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
const App = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const appId = searchParams.get("appId");
    if (appId) {
      sessionStorage.setItem("appId", appId);
    } else {
      sessionStorage.setItem("appId", process.env.REACT_APP_USE_CLIENT_ID || 'idm');
    }
  }, []);
  return (
    <LanguageProvider>
      <UserDataProvider>
        <Routes>
          <Route exact path="/" element={<Wizard />}></Route>
        </Routes>
      </UserDataProvider>
    </LanguageProvider>
  );
};

export default App;
