import React from 'react';
import { Box } from '@material-ui/core';
import styles from './Enrollment.module.scss';
// import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const FisTranslatedAlert = ({ type, message='Please provide a valid One time passcode' }) => {
  return (
    <Box
      style={{
        display: 'flex',
        border: '1px solid #FFAA00',
        color: '#FFAA00',
        padding: '10px',
        marginTop: '5px',
        borderRadius: '3px',
        letterSpacing: 'normal',
        fontSize: '0.9rem',
      }}
    >
      {/* <Box className={type === 'success' ? styles.successAlert : styles.invalidOtp}> */}
      {/* {type === 'success' ? (
        ) : (
          <CancelRoundedIcon fontSize="small" style={{ marginRight: '10px' }} />
          )} */}
      <InfoOutlinedIcon
        fontSize="small"
        style={{ color: '#FFAA00', marginRight: '10px' }}
      />
      {message}
    </Box>
  );
};

export default FisTranslatedAlert;
