import React, { useEffect, useState } from "react";

import { CssBaseline } from "@material-ui/core";
import { useIdleTimer } from "react-idle-timer";
import cookie from "js-cookie";
import { axiosInstance } from "../services/customaxios";
import { useGlobalUserData } from "../lib/globalUserData";
import MainHeader from "../components/Header/MainHeader";
import Footer from "../components/Footer/Footer";
// import { getAccessToken } from '../services/accessToken';
import MigrationChangePassword from "../MigrationWeb/MigrationChangePassword";
import Enroll from "../Enrollment/Enroll";
import EnrollmentSuccess from "../Enrollment/EnrollmentSuccess";
import EnrollCredentials from "../Enrollment/EnrollCredentials";
import EnrollPassword from "../Enrollment/EnrollPassword";
import ChangePasswordSuccess from "../Enrollment/ChangePasswordSuccess";
import Login from "../Enrollment/Login";
import FISTroubleSIgnIn from "../Enrollment/TroubleSignIn";
import Terms from "../Enrollment/Terms";
import OtpChoice from "../Enrollment/OtpChoice";
import Otp from "../Enrollment/Otp";
import OtpChoiceLogin from "../Enrollment/OtpChoiceLogin";
import OtpLogin from "../Enrollment/OtpLogin";
import { getMessage } from "../utils/status";
import { getURLParameters } from "../utils/getUrlParameters";
import AdditionalVerification from "../Enrollment/AdditionalVerification";
import LoaderFis from "../components/Loader/LoaderFIS";
import { formatDevices } from "../utils/formatDevices";
import { useTranslation } from "../contexts/Localization";
import TempPassword from "../Enrollment/TempPassword";
import NewComponent from "../Enrollment/NewComponent";

const Wizard = () => {
  const timeOutMillis = localStorage.getItem("timeMillis") || 3600000;
  const {
    step,
    changeStep,
    updateGlobalToken,
    showGlobalAlert,
    updateUserData,
    userData,
    logoutbtn,
    setLogoutbtn,
  } = useGlobalUserData();
  const { apiLanguage } = useTranslation();

  // const [referrer, setReferrer] = useState('');
  // setReferrer(document.referrer);
  // console.log("referrer url", referrer);
  // updateUserData({
  //   ...userData,
  //   referrer: referrer,
  // });

  const [loading, setLoading] = useState(false);
  const handleOnIdle = () => {
    if (
      step !== "migrationui" &&
      step !== "fislogin" &&
      step !== "fisloginusername"
    ) {
      changeStep("fislogin");
      showGlobalAlert({
        type: "error",
        message: "You need to enter your username again.",
      });
      updateUserData({
        ...userData,
        userpassword: "",
        password: "",
        globalShowPasswordField: false,
      });
    }
    console.log("b644fe49-4646-4186-a946-c8b8b733e8d0");
    // handled in fislogin resetUserData();
    // const preLoadUserId = localStorage.getItem('rememberedUserId');
    // const preLoadMaskUserId = mask(
    //   preLoadUserId || ''
    // );
    // updateUserData({
    //   ...userData,
    //   // userid: preLoadUserId,
    //   // useridMaskedValue: preLoadMaskUserId,
    //   rememberMe: preLoadUserId ? true : false,
    //   userpassword: ''
    // });
    cookie.remove("JSESSIONID", { path: "", domain: ".orientalbank.com" });
  };

  const handleOnAction = () => {
    console.log(
      "0c16065e149761f82f0a9f357edfa74741c6f6a01d8bdf88f5e893b573dbd132"
    );
  };

  // remove later const removeCookie = () => {
  //   console.log('cookie');
  //   // console.log(cookie.get('JSESSIONID', { path: '', domain: '.orientalbank.com' }));
  //   cookie.set('JSESSIONID', '4646-4186-a946-c8b8b73-5e149761f82f0a9f357edfa74741c6f6', { expires: 7, path: '', domain: '.orientalbank.com' })
  //   // cookie.remove('JSESSIONID', { path: '/', domain: '.orientalbank.com' });
  //   console.log(cookie.get('JSESSIONID'));
  //   console.log(cookie.get('VERYCOOLKEY'));
  //   changeStep('fisloginusername');
  //   updateUserData({...userData, globalShowPasswordField: false });
  // };
  const { isIdle, start } = useIdleTimer({
    timeout: timeOutMillis,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    startManually: true,
    stopOnIdle: true,
    events: [
      "DOMMouseScroll",
      "click",
      "keypress",
      "submit",
      "keydown",
      "keyup",
    ],
  });
  const startTimer = () => {
    start();
    const interval = setInterval(() => {
      if (isIdle()) {
        clearInterval(interval);
      }
    }, 1000);
  };
  const urlParam = new URLSearchParams(window.location.search);

  const getCurrentComponent = (stepName) => {
    const components = {
      enrollment: <Enroll />,
      enrollmentcredentials: <EnrollCredentials />,
      enrollmentpassword: <EnrollPassword />,
      // troublesignin: <TroubleSignin />,
      enrollmentsuccess: <EnrollmentSuccess />,
      migrationchangepassword: <MigrationChangePassword />,
      migrationchangepasswordsuccess: <ChangePasswordSuccess />,
      fisloginpassword: <Login />,
      migrationui: <Login />,
      fislogin: <Login />,
      fisloginusername: <Login />,
      fistroublesignin: <FISTroubleSIgnIn />,
      fisterms: <Terms />,
      fistroubleotpchoice: <OtpChoice />,
      fisotp: <Otp />,
      fisloginotpchoice: <OtpChoiceLogin />,
      fisloginotp: <OtpLogin />,
      additionalverification: <AdditionalVerification />,
      tempPassword: <TempPassword />,
      newFlow: <NewComponent />,
    };
    console.log("step", stepName);
    if (stepName === "newFlow") {
      setLogoutbtn(true);
    }
    return components[stepName];
  };
  useEffect(() => {
    console.log("lllllllllllllllllll");
    startTimer();
    async function getToken() {
      // const token = await getAccessToken();
      // updateGlobalToken({ token });
    }
    getToken();
    console.log("document.referrer", {
      referrer: document.referrer,
      windowDocRef: window.document.referrer,
      DocRef: Document.referrer,
      fisurl: process.env.REACT_APP_FIS_LOGIN_UI_URL,
    });
    const paramsObj = getURLParameters(urlParam.toString());
    if (paramsObj.tempPassword) {
      changeStep("tempPassword");
    } else {
      if (
        !document.referrer ||
        process.env.REACT_APP_FIS_LOGIN_UI_URL?.indexOf(document?.referrer) ===
          -1
      ) {
        setLoading(true);
        axiosInstance
          .post(
            `${process.env.REACT_APP_IDM_API}authentication/session`,
            {},
            {
              withCredentials: "include",
              headers: {
                appid: "idm",
                "Content-Type":
                  "application/x-www-form-urlencoded;charset=UTF-8",
                "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
              },
            }
          )
          .then(({ data }) => {
            // setLoading(false);
            console.log("data");
            console.log(data);
            if (
              data.sessionValidationStatus === "VALID_SESSION" &&
              localStorage.getItem("fisToken") &&
              paramsObj.appId !== "dummy"
            ) {
              window.open(
                `${
                  process.env.REACT_APP_FIS_LOGIN_UI_URL
                }services/authenticate?token=${localStorage.getItem(
                  "fisToken"
                )}`,
                "_self"
              );
              // window.open(
              //   `${process.env.REACT_APP_FIS_LOGIN_UI_URL}#/Login`,
              //   '_self'
              // );
              // }
              // else if (
              //   data.sessionValidationStatus === "VALID_SESSION" &&
              //   paramsObj.appId === "dummy"
              // ) {
              //   setLoading(false);
              //   changeStep("newFlow");
              //   axiosInstance
              //     .post(
              //       `${process.env.REACT_APP_IDM_API}authentication/sessionClose`,
              //       {},
              //       {
              //         withCredentials: "include",
              //         headers: {
              //           appid: "dummy",
              //           "Content-Type":
              //             "application/x-www-form-urlencoded;charset=UTF-8",
              //           "x-api-key":
              //             process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
              //         },
              //       }
              //     )
              //     .then(() => {
              //       setLoading(false);
              //     })
              //     .catch((err) => {
              //       setLoading(false);
              //       console.log(err);
              //     });
              //   localStorage.removeItem("fisToken");
            } else {
              setLoading(false);
              // changeStep('fislogin');
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        // window.history.forward();
        window.history.pushState(null, null, "");
        window.onpopstate = function () {
          // window.location.href = '';
          location.replace("");
          window.history.pushState(null, null, "");
          history.go(1);
        };
        setLoading(true);
        axiosInstance
          .post(
            `${process.env.REACT_APP_IDM_API}authentication/sessionClose`,
            {},
            {
              withCredentials: "include",
              headers: {
                appid: "idm",
                "Content-Type":
                  "application/x-www-form-urlencoded;charset=UTF-8",
                "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
              },
            }
          )
          .then(() => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
        localStorage.removeItem("fisToken");
      }
    }
    if (paramsObj.migration) {
      changeStep("migrationui");
    }
    if (paramsObj.post) {
      updateUserData({ ...userData, fisGetRequest: true });
    }
    if (paramsObj.wm) {
      localStorage.setItem("wm", "yes");
      updateUserData({ ...userData, waveMakerRedirect: true });
    }
    if (paramsObj.unlockStatus) {
      console.log("paramsObj.unlockStatus");
      console.log(paramsObj.unlockStatus);
      const unlockCode = parseInt(paramsObj.unlockStatus, 10);
      if (unlockCode === 200) {
        showGlobalAlert({
          type: "success",
          message: "Your account has been reset, please try to login now.",
        });
      } else {
        const { body } = getMessage(unlockCode);
        showGlobalAlert({
          type: "error",
          message: body,
        });
      }
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <MainHeader />
      {/* <Container component="main" maxWidth="xs"> */}
      <div style={{ maxWidth: "100%", overflowX: "hidden" }}>
        {getCurrentComponent(step)}
      </div>
      {/* </Container> */}
      <Footer />
      <LoaderFis open={loading} />
    </>
  );
};

export default Wizard;
