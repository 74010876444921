import { makeStyles } from '@material-ui/core';
import { commonStyle } from './LoaderStyles';

export const MigLoaderStyles = () => {
  return makeStyles((theme) => {
    const style = commonStyle(theme);
    return ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#E37617',
    },
    ...style,
  })});
};

