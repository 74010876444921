import React from "react";
import { Box } from "@material-ui/core";
import styles from "./Enrollment.module.scss";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useTranslation } from "../contexts/Localization";

const FisAlert = ({
  type,
  message = "Please provide a valid One time passcode",
}) => {
  const { t } = useTranslation();
  return (
    <Box
      id={type === "success" ? "alertMsg" : "errMsg"}
      className={type === "success" ? styles.successAlert : styles.invalidOtp}
    >
      {type === "success" ? (
        <InfoOutlinedIcon fontSize="small" style={{ marginRight: "10px" }} />
      ) : (
        <CancelRoundedIcon fontSize="small" style={{ marginRight: "10px" }} />
      )}
      <span
        style={{
          letterSpacing: "normal",
          fontSize: "0.9rem",
        }}
      >
        {t(message)}
      </span>
    </Box>
  );
};

export default FisAlert;
