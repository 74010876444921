import React, { useEffect, useState } from 'react';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import './Requirement.module.scss';
import { useTranslation } from '../../contexts/Localization';
import checkmarkValid from '../../images/checkmarkValid.svg';

export const Requirement = ({ value, requirement }) => {
  const [isValid, setIsValid] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setIsValid(requirement.validator(value));
  }, [value, requirement]);

  return (
    <div className="requirement">
      <div
        className={isValid ? 'valid' : 'invalid'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '15px',
          // border: '2px solid red'
          // border: '1px solid green'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            // border: '2px solid red',
            marginLeft: '7px',
          }}
        >
          {isValid ? (
            // <CheckCircleOutlineIcon
            //   style={{ width: '0.75em', height: '0.75em', color: '#128A08' }}
            // />
            <img
              style={{ width: '20px', height: '20px', color: '#128A08' }}
              src={checkmarkValid}
            ></img>
          ) : (
            <RadioButtonUncheckedIcon
              style={{
                width: '0.4em',
                height: '0.4em',
                color: 'black',
                marginLeft: '4px',
              }}
            />
          )}
        </div>
        {<>&nbsp;&nbsp;</>}
        <div
          style={
            isValid
              ? {
                  color: '#128A08',
                  letterSpacing: '0.2px',
                  fontSize: '12px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }
              : {
                  color: 'black',
                  letterSpacing: '0.2px',
                  fontSize: '12px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  marginLeft: '4px',
                }
          }
        >
          {t(requirement.text)}
        </div>
      </div>
    </div>
  );
};
