export const getReqArray = (userId) => {
  return (
    [
      {
        validator: (val) => {
          return val.length > 7 && val.length < 16;
        },
        text: 'Password must have a minimum of 8 characters and a maximum of 15 characters.',
        reqSatisfied: false,
      },
      {
        validator: (val) => val !== userId,
        text: 'User ID and Password cannot be the same.',
        reqSatisfied: false,
      },
      {
        validator: (val) => /^(?!.*(.)\1\1.*).*$/i.test(val),
        text: 'Cannot repeat more than 2 same character in a row.',
        reqSatisfied: false,
      },
      {
        validator: (val) =>
          /[A-Za-zÑñÁáÉéÍíÓóÚúü]{1}/.test(val) &&
          /(\D*\d)/.test(val) &&
          !/[><\\']/.test(val),
        text: "All letters, numbers and special characters (excluding \\, <, >, ').",
        reqSatisfied: false,
      },
      {
        validator: (val) =>
          /(\D*\d)/.test(val) &&
          /[A-Za-zÑñÁáÉéÍíÓóÚúü]{1}/.test(val) &&
          !/[><\\']/.test(val),
        text: 'One Character and one number.',
        reqSatisfied: false,
      },
      {
        validator: (val) => !/\s/.test(val),
        text: 'Cannot include spaces.',
        reqSatisfied: false,
      },
    ]
  )
};