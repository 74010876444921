/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@material-ui/core/Button";
import { Modal, Grid, Box, Typography } from "@material-ui/core";
import styles from "./Enrollment.module.scss";
import { AlertStyle } from "../components/Alert/AlertStyle";
import FisAlert from "./FisAlert";
import { useGlobalUserData } from "../lib/globalUserData";
import { useTranslation } from "../contexts/Localization";

const useStyles = AlertStyle();

function FisModal({
  open,
  alertTitle,
  alertBody,
  onClose,
  onConfirm = () => {
    // fallback if undefined props get passed
  },
}) {
  const { changeStep } = useGlobalUserData();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    onClose();
    changeStep("fislogin");
  };

  return (
    <Modal
      className={classes.backdrop}
      open={open}
      aria-describedby="alert notification"
      onClose={handleClose}
    >
      <div style={{ outline: "none" }}>
        <Grid container>
          <Box className={styles.modal}>
            <Box display={"flex"} width="100%" justifyContent={"center"}>
              <Typography
                id="popUpmsg"
                style={{
                  fontFamily: "'TTNormsRegular', Arial, Helvetica, sans-serif",
                  fontSize: "30px",
                  fontWeight: "700",
                }}
              >
                {alertTitle || "Error Occurred"}
              </Typography>
            </Box>
            <FisAlert message={alertBody} id="alertMsg" />
            <Box className={styles.btn_wrapper}>
              <Button
                id="tryAgain"
                name="tryAgain"
                variant="text"
                className={styles.fismodal_button}
                onClick={onConfirm}
              >
                {t("Try Again")}
              </Button>
              <Button
                id="closeBtn"
                name="closeBtn"
                variant="text"
                className={styles.fismodal_button}
                onClick={handleClose}
              >
                {t("Close")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </div>
    </Modal>
  );
}

export default FisModal;
