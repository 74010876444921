import { Requirement } from './Requirement';
import React, { useCallback, useEffect } from 'react';
import warningIconInfo from '../../images/warningIconInfo.svg';
import { useTranslation } from '../../contexts/Localization';

export const Requirements = ({
    value,
    requirements,
    onValidChange,
  }) => {
  const { t } = useTranslation();
  // maybe needed const validChangeCb = onValidChange;
  // const validChangeCb = useCallback(onValidChange, []);
  // console.log('value');
  // console.log(value);

  useEffect(() => {
    onValidChange(
      requirements.every(r => r.validator(value))
    );
  }, [value, requirements, onValidChange]);

  return (
    <>
      {requirements.map((r, index) => (
        <Requirement
          key={index}
          value={value}
          requirement={r}
          onValidChange={onValidChange}
        />
      ))}
      {
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '15px',
          }}
        >
          <div
            style={{
              marginTop: '30',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              marginLeft: '8px',
            }}
          >
            <img
              style={{ width: '20px', height: '20px' }}
              src={warningIconInfo}
            ></img>
          </div>
          {<>&nbsp;&nbsp;</>}
          <div
            style={{
              letterSpacing: '0.2px',
              fontSize: '12px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            {t('**Note: Password is Case sensitive.')}
          </div>
        </div>
      }
    </>
  );
};
