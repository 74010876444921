/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  OutlinedInput,
  Button,
  InputAdornment,
  IconButton,
  Tooltip,
  Zoom,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import styles from "./Enrollment.module.scss";
import { useGlobalUserData } from "../lib/globalUserData";
import { enrollTooltipStyles } from "./EnrollTooltipStyles";
import { Requirements } from "../components/Requirements/Requirements";
import { useTranslation } from "../contexts/Localization";
import { FISWrapper } from "./FISWrapper";
import LoaderFis from "../components/Loader/LoaderFIS";
import { axiosInstance } from "../services/customaxios";
import { getEnrollMessage } from "./enrollStatus";
import FisAlert from "./FisAlert";
import warningIconInfo from "../images/warningIconInfo.svg";

const useStyles = enrollTooltipStyles();

const EnrollCredentials = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { changeStep, updateUserData, userData, globalAlert, showGlobalAlert } =
    useGlobalUserData();
  const [formData, setFormData] = useState({ accountType: "empty" });
  const [formErrors, setFormErrors] = useState({});
  const { t } = useTranslation();
  const [valid, setValid] = useState(false);

  let reqArray = [
    {
      validator: (val) => val.length > 7 && val.length < 16,
      text: "User ID must be between 8 and 15 characters.",
      reqSatisfied: false,
    },
    {
      validator: (val) =>
        /(\D*\d){2,}/.test(val) && /[A-Za-zÑñÁáÉéÍíÓóÚúü]{2}/.test(val),
      text: "Must contain at least 2 letters and 2 numbers.",
      reqSatisfied: false,
    },
    {
      validator: (val) => !/\s/.test(val),
      text: "Cannot include spaces.",
      reqSatisfied: false,
    },
  ];
  useEffect(() => {
    showGlobalAlert({ type: "", message: "" });
    return () => {
      showGlobalAlert({ type: "", message: "" });
    };
  }, []);

  const onFormData = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setValid(
      !reqArray
        .map((req) => req.validator(event.target.value))
        .some((val) => val === false)
    );
  };
  const handleUseridContinue = () => {
    setLoading(true);

    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}enrollments/duplicateUser`,
        {
          sessionId: userData.sessionId,
          username: formData.userid,
        },
        {
          headers: {
            appid: "idm",
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_ENROLLMENT,
          },
        }
      )
      .then((res) => {
        updateUserData({ ...userData, userid: formData.userid });
        const code = res.data.status.code;
        if (code === 305) {
          showGlobalAlert({
            type: "success",
            message: getEnrollMessage(code).body,
          });
        } else if (code !== 200) {
          showGlobalAlert({
            type: "error",
            message: getEnrollMessage(code).body,
          });
        } else {
          changeStep("enrollmentpassword");
        }
      })
      .catch((err) => {
        showGlobalAlert({
          type: "error",
          message: getEnrollMessage("defaultCode").body,
        });
        console.error(err);
      })
      .finally(() => {
        updateUserData({ ...userData, userid: formData.userid });
        setLoading(false);
      });
  };

  return (
    <FISWrapper>
      <Box className={styles.heading} id="headingBox">
        {t("Create User ID")}
      </Box>
      <Box
        className={styles.subHeading}
        id="subHeadingBox"
        style={{ textAlign: "center" }}
      >
        {t(
          "Please create a User ID to continue enrolling in online banking. You will use this ID to login to your account"
        )}
      </Box>
      <form
        noValidate
        id="enrollcredentials"
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{ marginTop: "20px", width: "100%" }}
      >
        {globalAlert.message && (
          <FisAlert message={globalAlert.message} type={globalAlert.type} />
        )}
        <Box className={styles.boxMarginTop} id="marginBox">
          <Tooltip
            arrow
            placement={"bottom"}
            enterTouchDelay={50}
            leaveTouchDelay={500}
            leaveDelay={500}
            TransitionComponent={Zoom}
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            title={
              <>
                <Requirements
                  value={formData.userid || ""}
                  requirements={reqArray}
                  onValidChange={(isValid) => {}}
                />
                {
                  <div
                    // className={isValid ? 'valid' : 'invalid'}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "15px",
                      // border: '2px solid red'
                      // border: '1px solid green'
                    }}
                  >
                    <div
                      style={{
                        marginTop: "30",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        marginLeft: "8px",
                      }}
                    >
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={warningIconInfo}
                      ></img>
                      {/* <InfoOutlinedIcon
                        style={{
                          width: '1em',
                          height: '1em',
                          color: '#f5821f',
                          // transform: 'rotate(90deg) scaleX(-1)',
                          rotate: '0.5turn',
                        }}
                      /> */}
                    </div>
                    {<>&nbsp;&nbsp;</>}
                    <div
                      style={{
                        // color: 'white',
                        letterSpacing: "0.2px",
                        fontSize: "12px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      {t(
                        "All letters, numbers and special characters (excluding space)"
                      )}
                    </div>
                  </div>
                }
              </>
            }
          >
            <FormControl
              variant="outlined"
              style={{
                width: "100%",
              }}
            >
              <InputLabel htmlFor="component-outlined">
                {t("User ID")}
              </InputLabel>
              <OutlinedInput
                id="userid"
                name="userid"
                autoFocus
                // fullWidth
                className={styles.passwordInput}
                onChange={onFormData}
                placeholder={t("User ID")}
                fullWidth
                inputProps={{ maxLength: 15 }}
                autoComplete="off"
                value={formData.userid}
                type={"text"}
                // className={styles.customInput}
              />
            </FormControl>
          </Tooltip>
        </Box>
        <Grid
          container
          spacing={2}
          style={{ marginTop: 180 }}
          className={styles.btn_wrapper_pass}
          justifyContent="space-around"
        >
          <Grid item sm={6}>
            <Button
              fullWidth
              id="continue-btn"
              name="continueBtn"
              data-testid="continueBtn"
              variant="contained"
              type="submit"
              color="secondary"
              className={styles.submit_password}
              onClick={handleUseridContinue}
              disabled={!valid}
            >
              {t("Continue")}
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              type="button"
              id="cancel-btn"
              data-testid="cancelBtn"
              name="cancelBtn"
              fullWidth
              variant="outlined"
              color="secondary"
              // className={styles.button_cancel}
              onClick={() => changeStep("fislogin")}
            >
              {t("Cancel")}
            </Button>
          </Grid>
        </Grid>
      </form>
      <LoaderFis open={loading} />
    </FISWrapper>
  );
};

export default EnrollCredentials;
