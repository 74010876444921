import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import { Grid, Box, Button } from "@material-ui/core";
import styles from "./Enrollment.module.scss";
import { useGlobalUserData } from "../lib/globalUserData";
import { useTranslation } from "../contexts/Localization";
import enrollSuccessTick from "../images/enrollSuccessTick.svg";

const EnrollmentSuccess = () => {
  const { changeStep } = useGlobalUserData();
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center">
      <Grid item md={4}>
        <Box className={styles.container} style={{ borderRadius: "8px" }}>
          <Box
            className={styles.heading}
            style={{ fontSize: "28px", fontWeight: "800" }}
            id="enrollCompleted"
          >
            {t("Enrollment Complete!")}
          </Box>
          <Box style={{ margin: "70px", fontSize: "6rem" }}>
            {/* <CheckCircleIcon fontSize="inherit" style={{ fill: '#661c69' }} /> */}
            <img src={enrollSuccessTick} />
          </Box>
          <Box style={{ width: "300px" }}>
            {/* <Button
              className={styles.continue_button}
              style={{ width: '100%' }}
              onClick={() => changeStep('migrationui')}
            >
              {t('Continue To Login')}
              </Button> */}
            <Button
              variant="text"
              id="submitBtn"
              name="submitBtn"
              type="submit"
              style={{ fontSize: "14px" }}
              className={styles.login_button}
              onClick={() => changeStep("migrationui")}
            >
              {t("Continue To Login")}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EnrollmentSuccess;
