import { makeStyles } from "@material-ui/core"

export const AlertStyle = () => {
  return (
    makeStyles((theme) => ({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: '20px',
      },

      root: {
        display: "flex",
        alignItems: "center",
        maxWidth: "352px",
        maxHeight: "245px",
        minWidth: "260px",
        justifyContent: "center",
        borderRadius: '40px',
        border: '3px solid #E37617',
        [theme.breakpoints.down('400')]: {
          maxHeight: "unset",
        },
      },

      card_content: {
        textAlign: "center",
        padding: "0px !important",
        margin: "0px",
      },

      saveAndExit: {
        background: "#661C69 0% 0% no-repeat padding-box",
        color: "#ffffff",
        letterSpacing: "0.1px",
        font: "normal normal normal 14px/17px Arial",
        width: "100px",
        height: "28px",
        margin: "18px 0 24px 0",
        opacity: 1,
        textTransform: "none",
        borderRadius: "23px",
        "&:hover": {
          backgroundColor: "#661c69"
        },
        "&:active": {
          backgroundColor: "#661c69"
        },
      },

      textOneStyle: {
        font: "normal normal bold 18px/21px Arial",
        color: "#000000",
        letterSpacing: "0.12px",
        width: "100%",
        margin: "29px auto",
      },

      textTwoStyle: {
        font: "normal normal normal 14px/17px Arial",
        color: "#000000",
        letterSpacing: "0.1px",
        width: "80%",
        margin: "0px auto 10px",

      },

      close_icon: {
        width: "34px",
        height: "34px",
        cursor: "pointer",
        margin: "5px 5px 0 0"
      },

      modal_close: {
        color: "#661C69",
        textAlign: "right",
        height: "35px",
      },

      closeOverlay: {
        top: '29px',
        color: '#ffffff',
        width: '28px',
        cursor: 'pointer',
        height: '28px',
        position: 'relative',
        background: '#E37617',
        '&:hover': {
          backgroundColor: '#E37617',
        },
      }
    }))
  );
};
