import React from 'react';
import ReactDOM from 'react-dom';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import THEME from './Themes/Theme';
import './index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <>
    <StylesProvider injectFirst>
      <ThemeProvider theme={THEME}>
        <Router>
        <App />
        </Router>
      </ThemeProvider>
    </StylesProvider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
