import axios from "axios";

export const axiosInstance = axios.create();

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  const configg = config;

  // configg.headers["clientid"] = process.env.REACT_APP_USE_CLIENT_ID;
  configg.headers["appid"] = sessionStorage.getItem('appId') || 'idm';

  return config;
});
