import React from 'react';
import { Grid, Box } from '@material-ui/core';
import styles from './Enrollment.module.scss';

export const FISWrapper = ({ children }) => {
  return (
    <Grid container justifyContent="center">
      <Box className={styles.container}>{children}</Box>
    </Grid>
  );
};
