const enrollStatus = {
  303: {
    title: "Invalid Credentials",
    body: "Your contact information is not available. Please contact customer service desk to update your contact details and try again.",
  },
  193: {
    title: "Invalid Credentials",
    body: "You are already registered for online banking. Please Sign In.",
  },
  302: {
    title: "Invalid Credentials",
    body: "We are unable to verify your details. Please review and try again.",
  },
  305: {
    title: "Invalid Credentials",
    body: "This user ID is not available. Please choose another ID to continue.",
  },
  304: {
    title: "Invalid Credentials",
    body: "This user ID is not available. Please choose another ID to continue.",
  },
  500: {
    body: "We are unable to process your request. Please try again later.",
    title: "Error occurred",
  },
  defaultCode: {
    body: "We are unable to process your request. Please try again later.",
    title: "Error occurred",
  },
};

const getEnrollMessage = (code) => {
  return enrollStatus[code || "defaultCode"] || enrollStatus.defaultCode;
};

export { enrollStatus, getEnrollMessage };

// {
//   "statusCode": 0,
//   "id": null,
//   "status": {
//       "code": 302,
//       "msg": "Invalid Account Information"
//   }
// }
