import React from "react";
import { axiosInstance } from "../services/customaxios";
import { getMessage } from "./status";
export const fetchFIS = async (token) => {
  try {
    const fetchParams = new URLSearchParams();
    fetchParams.append("token", token);
    const res = await axiosInstance.post(
      `${process.env.REACT_APP_FIS_LOGIN_UI_URL}services/authenticate`,
      fetchParams,
      {
        withCredentials: "include",
        redirect: "follow",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log("Error after the FIS call");
    console.error(err);
  }
};

export const FISFlow = async (respn, userData, changeStep, showGlobalAlert) => {
  localStorage.setItem("fisToken", respn.data.accessToken);
  async function handShakeFIS() {
    const handshakeData = await fetchFIS(respn.data.accessToken);
    console.dir(handshakeData);
    return handshakeData;
  }
  if (userData.fisGetRequest) {
    const handshakeResponse = await handShakeFIS();
    console.log("handshakeResponse");
    console.log(handshakeResponse);
    if (!handshakeResponse) {
      showGlobalAlert({
        type: "error",
        message: getMessage("defaultCode").body,
      });
      changeStep("fislogin");
    } else {
      window.open(handshakeResponse.redirectUrl, "_self");
    }
  } else {
    if (sessionStorage.getItem('appId') === "dummy") {
      console.log("getmsg resp", getMessage("defaultCode"));
      changeStep("newFlow");
    } else if (localStorage.getItem("wm") === "yes") {
      // https://cloud.wavemakeronline.com/pk7cgd7g3ttt/d1_ssp/#/Main?token=xxxxx
      window.open(
        `${process.env.REACT_APP_WAVEMAKER_URL}?token=${respn.data.accessToken}`,
        "_self"
      );
    } else {
      window.open(
        `${process.env.REACT_APP_FIS_LOGIN_UI_URL}services/authenticate?token=${respn.data.accessToken}`,
        "_self"
      );
    }
  }
};
