import { v4 as uuidv4 } from 'uuid';

import { orderBy } from './orderBy';
import { uniqueElementsBy } from './uniqueElementsBy';

export const formatDevices = (deviceList) => {
  const uniqDevices = uniqueElementsBy(
    deviceList.map((d) => ({
      label: d.label,
      device: d.groupLabel === 'EMAIL' ? 'EMAIL' : 'PHONE',
      deviceInfo: [],
      type: d.type,
      uid: uuidv4(),
    })),
    (a, b) => a.label === b.label && (a.type !== b.type)
  );

  const newDevList = deviceList.map((dev) => ({ ...dev, saved: false }))
  for (const uniqDevice of uniqDevices) {
    // for (const device of deviceList) {
      // for (let i = 0; i < newDevList.length; i += 1) {
      //   console.log('deviceList.length');
      //   console.log(newDevList.length);
      //   if (
      //     uniqDevice.label === newDevList[i].label &&
      //     !uniqDevice.deviceInfo.some((dev) => dev.type === newDevList[i].type) &&
      //     !newDevList[i].saved
      //   ) {
      //     // if (deviceList[i].type !== uniqDevice?.type) {
      //     const { label, ...deviceData } = newDevList[i];
      //     uniqDevice.deviceInfo.push(deviceData);
      //     newDevList[i].saved = true;
      //     // const foio = deviceList.splice(i, 1);
      //     // console.log('foio....................');
      //     // console.log(foio);
      //     // }
      //     // push ONLY if there's NO device with that type already in it!
      //     // and once you push, delete it from previous list.
      //   }
      // }
    // }
    for (const device of newDevList) {
      if (
        uniqDevice.label === device.label &&
        !uniqDevice.deviceInfo.some((dev) => dev.type === device.type) &&
        !device.saved
      ) {
        const { label, ...deviceData } = device;
        uniqDevice.deviceInfo.push(deviceData);
        device.saved = true;
      }
    }
  }

  return orderBy(
    uniqDevices.map((ud) => ({
      ...ud,
      deviceInfo: orderBy(ud.deviceInfo, ['groupLabel'], ['asc']),
    })),
    ['device'],
    ['desc']
  );
};
