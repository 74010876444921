import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useGlobalUserData } from "../lib/globalUserData";
import styles from "./Enrollment.module.scss";

const NewComponent = () => {
  const { changeStep, setLogoutbtn } = useGlobalUserData();
  const [counter, setCounter] = useState(900);
  const [showPopup, setShowPopup] = useState(false);
  const promptBeforeIdle = 120;

  const onIdle = () => {
    setShowPopup(false);
  };

  const onActive = () => {
    setCounter(900);
    setShowPopup(false);
  };

  const onPrompt = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter <= 0) {
          clearInterval(interval);
          handleLogout();
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: 15 * 60 * 1000,
    promptBeforeIdle: promptBeforeIdle * 1000,
  });

  const handleSignIn = () => {
    setCounter(900);
    activate();
    setShowPopup(false);
  };

  const handleLogout = () => {
    changeStep("fislogin");
    setLogoutbtn(false);
  };

  const format = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${padTime(seconds)}`;
  };
  const padTime = (time) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <h1 id="welcome-text">Welcome to the new portal!</h1>
      {showPopup && (
        <Modal open={showPopup}>
          <div
            style={{
              position: "absolute",
              top: "20%",
              left: "30%",
              right: "30%",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "white",
                alignItems: "center",
                flexDirection: "column",
                padding: "30px",
              }}
            >
              <h2>Your session will expire soon!</h2>
              <h3>{format(counter)}</h3>
              <h4>Do you want to stay logged in?</h4>
              <div style={{ display: "flex", gap: "15px" }}>
                <button
                  onClick={handleSignIn}
                  className={styles.newComp_button}
                >
                  Stay Logged In
                </button>
                <button
                  onClick={handleLogout}
                  className={styles.newComp_button}
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default NewComponent;
